import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 21,
  },
  nav: {
    backgroundColor: "#f5f5f5",
    padding: 10,
    [theme.breakpoints.down("sm")]: {
      padding: 5,
      margin: "9px 0px",
    },
  },
}));

export default () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "language/ar-flag.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className={classes.root}>
      <ListItem
        button
        aria-haspopup="true"
        aria-controls="lock-menu"
        aria-label="when device is locked"
        onClick={handleClick}
        className={classes.nav}
      >
        <ListItemIcon>
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt="en-flag"
            style={{ width: "100%" }}
          />
        </ListItemIcon>
        <ListItemText primary="English" />
      </ListItem>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}> العربية </MenuItem>
        <a href="https://en.mamas-co.com/">
          <MenuItem onClick={handleClose}>
          English
          </MenuItem>
        </a>
      </Menu>
    </div>
  );
};
