import React from "react";

// import local components
import { styled } from "@material-ui/core/styles";

import config from "../../data/SiteConfig";

// import local components
import Header from "../components/Header";
import Footer from "../components/Footer";
import SocialBar from "../components/SocialBar";

// import @material-ui components
import { Helmet } from "react-helmet";
import Container from "@material-ui/core/Container";

const Warrp = styled(Container)({
  padding: 0,
  backgroundColor: "#fbf7f7",
});

export default ({ children }) => {
  return (
    <Warrp>
      <Helmet>
        <meta name="description" content={config.siteDescription} />
        <html lang="ar" dir="rtl" />
        <link
          href="https://fonts.googleapis.com/css?family=Tajawal"
          rel="stylesheet"
        />

        <script>
          { `
             (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:2177946,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `}
        </script>

        <style type="text/css">{`
                * {
                    font-family: 'Tajawal', sans-serif;
                }

                 a {
                   color: #424244;
                   text-decoration: none;
                 }

              .MuiPaper-elevation1 {
                 box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 3%), 0px 1px 1px 0px rgb(0 0 0 / 4%), 0px 1px 3px 0px rgb(0 0 0 / 5%);
               }
          `}</style>
      </Helmet>

      <Header />
      {children}
      <SocialBar />
      <Footer />
    </Warrp>
  );
};
