import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "./../Icons/Search";

import LanguageMenu from "./../LanguageMenu";

import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";


const useStyles = makeStyles((theme) => ({

  headerWarrp: {
    display: "flex",
    padding: 0,
    backgroundColor: "white",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 3%), 0px 1px 1px 0px rgb(0 0 0 / 4%), 0px 1px 3px 0px rgb(0 0 0 / 5%)",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },

  logoWarrp: {
    padding: 20,
    height: 100,
    width: "40%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 20,
      display: "block",
    },
  },

  actionWarrp: {
    width: "60%",
    justifyContent: "flex-end",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "block",
    },
  },

  search: {
    padding: 22,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export default () => {
  const classes = useStyles();
  const [age, setAge] = React.useState("");
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo/ar-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className={classes.headerWarrp}>
      <div className={classes.logoWarrp}>
        <Img
          fluid={data.file.childImageSharp.fluid}
          alt="mamas-co"
          style={{ width: "100%" }}
        />
      </div>

      <div className={classes.actionWarrp}>
        <div className={classes.search}>
          <IconButton aria-label="search">
            <SearchIcon color="#5d5e60" size="32px" />
          </IconButton>
        </div>
        <LanguageMenu />
      </div>
    </div>
  );
};
