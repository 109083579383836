const config = {
  siteTitle: "مماس للصناعات الغذائية", // Site title.
  siteTitleShort: "مماس | الصفحة الرئيسية", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "مماس للصناعات الغذائية", // Alternative site title for SEO.
  siteLogo: "/logos/logo-mamas-co.png", // Logo used for SEO and manifest.
  siteUrl: "https://mamas-co.com/", // Domain of your website without pathPrefix.
  pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription:
    "انتاج و تعبئة بطاطس نصف مقلية فرز و تعبئة خضروات و فاكهة مجمدة", // Website description used for RSS feeds/meta description tag.
  siteRss: "/rss.xml", // Path to the RSS file.
  siteRssTitle: " التغذية الراجعة لمماس للصناعات الغذائية", // Title of the RSS feed
  //siteFBAppID: "1825356251115265", // FB Application ID for using app insights
  googleAnalyticsID: "G-21T8CM5TDT", // GA tracking ID.
  //disqusShortname: "https-vagr9k-github-io-gatsby-advanced-starter", // Disqus shortname.
  dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  dateFormat: "DD/MM/YYYY", // Date format for display.
  postsPerPage: 9, // Amount of posts displayed per listing page.
  contacts: {
    email: "contact@mamas-co.com",
    phone: "201032832009+",
    address: "قطعة رقم 6024 ،  ورش المنطقة الصناعية السادسة ، مدينة السادات , مصر",
  },
  pages: [
    {
      title: "عن مماس",
      url: "/page/about-mamas",
    },
    {
      title: "سياسة الخصوصية",
      url: "/page/privacy-policy",
    },
    {
      title: "الشروط و الاحكام",
      url: "/page/terms-and-conditions",
    },
  ],
  copyright: "Copyright © 2019-2021 |  All Rights Reserved to Mamas-co ", // Copyright string for the footer of the website and RSS feed.
  themeColor: "#c62828", // Used for setting manifest and progress theme colors.
  backgroundColor: "#e0e0e0", // Used for setting manifest background color.
};

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  config.pathPrefix = "";
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1);

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== "/")
  config.siteRss = `/${config.siteRss}`;

module.exports = config;
