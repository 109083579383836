import React from "react";

export default (props)=> {
  const{color, size } = props;
  return (

    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 512 512"
    >
      <rect
        width="416"
        height="320"
        x="48"
        y="96"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="32"
        rx="40"
        ry="40"
      ></rect>
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="32"
        d="M112 160l144 112 144-112"
      ></path>
    </svg>

  );
}
