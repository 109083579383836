import React from "react";
import {  makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";


import SendIcon from './../Icons/Send';

import FacebookIcon from './../Icons/Facebook';
import TwitterIcon from './../Icons/Twitter';
import WhatsappIcon from './../Icons/Whatsapp';
import RssIcon from './../Icons/Rss';





const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    width: "100%",
    height: 70,
    backgroundColor: "#80bb10",
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: { color: "inherit" },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0), // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));



export default () => {
  const classes = useStyles();
  return (
    <div className={classes.grow}>
      <Toolbar>
        <Typography className={classes.title} variant="h6" noWrap>
          Material-UI
        </Typography>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SendIcon color="white" size="25px"/>
          </div>
          <InputBase
            placeholder="Search…"
            classes={{ root: classes.inputRoot, input: classes.inputInput }}
            inputProps={{ "aria-label": "search" }}
          />
        </div>
        <div className={classes.grow} />
        <div className={classes.sectionDesktop}>
        <IconButton  aria-label="search">
          <FacebookIcon color="white" size="25px"/>
        </IconButton>
        <IconButton  aria-label="search">
          <TwitterIcon color="white" size="25px"/>
        </IconButton>
        <IconButton  aria-label="search">
          <WhatsappIcon color="white" size="25px"/>
        </IconButton>
        <IconButton  aria-label="search">
          <RssIcon color="white" size="25px"/>
        </IconButton>
        </div>
      </Toolbar>

    </div>
  );
};
