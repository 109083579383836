import React from "react";
import { styled, makeStyles } from "@material-ui/core/styles";
import { Link } from "gatsby";

import config from "../../../data/SiteConfig";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/Typography";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import MailIcon from "./../Icons/Mail";
import CallIcon from "./../Icons/Call";
import LocationIcon from "./../Icons/Location";

const CopyRight = styled(Typography)({
  textAlign: "center",
  padding: 10,
});

const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  item: {
    display: "flex",
    padding: "10px 0",
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <>
      <Container style={{ padding: 25 }}>
        <Grid container spacing={1}>
          <Grid item md={4} xs={12} sm={6}>
            <Typography variant="h6" className={classes.title}>
            معلومات الاتصال

            </Typography>
            <List dense={false}>
              <ListItem className={classes.item}>
                <ListItemIcon>
                  <MailIcon color="#80bb10" size="25px" />
                </ListItemIcon>
                <ListItemText primary={config.contacts.email} />
              </ListItem>
              <ListItem className={classes.item}>
                <ListItemIcon>
                  <CallIcon color="#80bb10" size="25px" />
                </ListItemIcon>
                <ListItemText primary={config.contacts.phone} />
              </ListItem>

              <ListItem className={classes.item}>
                <ListItemIcon>
                  <LocationIcon color="#80bb10" size="25px" />
                </ListItemIcon>
                <ListItemText primary={config.contacts.address} />
              </ListItem>
            </List>
          </Grid>

          <Grid item md={4} xs={12} sm={6}>
            <Typography variant="h6" className={classes.title}>
            روابط مهمة
            </Typography>
            <List dense={false}>
              {config.pages.map((page, index) => (
                <ListItem className={classes.item} key={index}>
                  <ListItemIcon>
                    <span style={{ color: "rgb(128, 187, 16)" }}> » </span>
                  </ListItemIcon>
                  <Link to={page.url}>
                    <ListItemText primary={page.title} />
                  </Link>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item md={4} xs={12} sm={6}></Grid>
        </Grid>
      </Container>

      <Divider />
      <CopyRight variant="body2" gutterBottom>
        {config.copyright}
      </CopyRight>
    </>
  );
};
